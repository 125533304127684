import React, { useRef } from 'react';

import { updateFoilPosition } from '@zola/zola-ui/src/animations/TextFoil/helper';
import { useAnimationContext } from 'components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import { FoilAnimationContext } from './FoilAnimationContext';

type FoilAnimationProviderProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export const FoilAnimationProvider: React.FC<FoilAnimationProviderProps> = ({
  children,
  style,
}) => {
  const foilRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLSpanElement>(null);
  const { showAnimations } = useAnimationContext();

  // TBD
  const easingValue = 1;
  if (showAnimations) {
    return (
      <div
        style={style}
        className="IcEnableContainerAndCursor"
        onMouseMove={e => {
          updateFoilPosition(e, easingValue, foilRef, overlayRef);
        }}
      >
        <FoilAnimationContext.Provider value={{ foilRef, overlayRef }}>
          {children}
        </FoilAnimationContext.Provider>
      </div>
    );
  }

  return (
    <FoilAnimationContext.Provider value={{ foilRef, overlayRef }}>
      {children}
    </FoilAnimationContext.Provider>
  );
};
