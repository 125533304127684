import styled from '@emotion/styled';
import { mapInFlowAssetToCSS } from 'components/publicWebsiteV2/util/mappers';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import type { DevicePartial } from 'components/publicWebsiteV2/util/getRelativeImgSize';

export const Subtitle = styled.div`
  margin-top: ${({ theme }) => theme.SPACING.SM};
  margin-bottom: ${({ theme }) => theme.SPACING.S24};
`;

export const Subcontainer = styled.div`
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    width: 100%;
    padding: 0;
  }
  p {
    white-space: pre-wrap;
  }
`;

export const TitleContainer = styled(Subcontainer)<{
  containerWidth?: number;
  device?: DevicePartial;
  inFlowAsset?: WInFlowThemeComponentAssetSummaryView;
}>`
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    border: none;
  }
  > div {
    display: inline-flex;
    flex-direction: column;
  }
  ${({ inFlowAsset, device, containerWidth, theme }) =>
    mapInFlowAssetToCSS(inFlowAsset, device, containerWidth, theme.MEDIA_QUERY)}
`;

export const Container = styled.div<{
  isHorizontalOrientation?: boolean;
  textAlignment: string;
}>`
  overflow-wrap: anywhere;
  position: relative;
  display: flex;
  flex-direction: ${({ isHorizontalOrientation }) => (isHorizontalOrientation ? 'row' : 'column')};
  align-items: ${({ textAlignment }) => (textAlignment === 'CENTER' ? 'center' : 'flex-start')};
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    flex-direction: column;
  }
  ${({ isHorizontalOrientation, theme }) =>
    isHorizontalOrientation &&
    `
${Subcontainer}, ${TitleContainer} {
    width: 50%;
    padding: 32px;

    ${theme.MEDIA_QUERY.MOBILE} {
      width: 100%;
      padding: 0;
    }
}
`}
`;
