import React from 'react';

// types
import type { WCmsPageImageView } from '@zola/svc-web-api-ts-client';

// components
import { DeviceDesktopIcon } from '@zola/zola-ui/src/components/SvgIconsV3/DeviceDesktop';
import { DeviceMobileIcon } from '@zola/zola-ui/src/components/SvgIconsV3/DeviceMobile';

// assets

// Layout Selector
import fullWidthAsset from 'assets/images/editWebsite/layoutSelector/full-width.png';
import marqueeAsset from 'assets/images/editWebsite/layoutSelector/marquee.png';
import sideBySideAsset from 'assets/images/editWebsite/layoutSelector/sbs.png';
import slideshowAsset from 'assets/images/editWebsite/layoutSelector/slideshow.png';
import stackedAsset from 'assets/images/editWebsite/layoutSelector/stacked.png';
import textOnlyAsset from 'assets/images/editWebsite/layoutSelector/text-only@2x.png';
import textOnlyHpdAsset from 'assets/images/editWebsite/layoutSelector/text-only@3x.png';
import bannerAsset from 'assets/images/editWebsite/layoutSelector/banner.png';

// Layout Photos (Edit Website)
import fiveBySevenPlaceholder from 'assets/images/editWebsite/layoutPhotos/five-by-seven.png';
import fullWidthPlaceholder from 'assets/images/editWebsite/layoutPhotos/full-width.png';
import marquee1Placeholder from 'assets/images/editWebsite/layoutPhotos/marquee-1.png';
import marquee2Placeholder from 'assets/images/editWebsite/layoutPhotos/marquee-2.png';
import marquee3Placeholder from 'assets/images/editWebsite/layoutPhotos/marquee-3.png';
import marquee4Placeholder from 'assets/images/editWebsite/layoutPhotos/marquee-4.png';
import sevenByFivePlaceholder from 'assets/images/editWebsite/layoutPhotos/seven-by-five.png';
import stacked1Placeholder from 'assets/images/editWebsite/layoutPhotos/stacked-1.png';
import stacked2Placeholder from 'assets/images/editWebsite/layoutPhotos/stacked-2.png';
import slideshowMultipage1Placeholder from 'assets/images/editWebsite/layoutPhotos/slideshow-1.png';
import slideshowMultipage2Placeholder from 'assets/images/editWebsite/layoutPhotos/slideshow-2.png';
import slideshowSinglepage1Placeholder from 'assets/images/editWebsite/layoutPhotos/slideshow-singlepage-1.png';
import slideshowSinglepage2Placeholder from 'assets/images/editWebsite/layoutPhotos/slideshow-singlepage-2.png';

import mobileFullWidthPlaceholder from 'assets/images/editWebsite/layoutPhotos/mobile-single-image-1-1.png';
import mobileMarquee1Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-marquee-1-image-1-1.png';
import mobileMarquee2Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-marquee-2-image-1-1.png';
import mobileMarquee3Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-marquee-3-image-1-1.png';
import mobileMarquee4Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-marquee-4-image-1-1.png';
import mobileSbs1Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-sbs-1-image-1-1.png';
import mobileSbs2Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-sbs-2-image-1-1.png';
import mobileSlideshow1Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-slideshow-1-image-1-1.png';
import mobileSlideshow2Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-slideshow-2-image-1-1.png';
import mobileStacked1Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-stacked-1-image-1-1.png';
import mobileStacked2Placeholder from 'assets/images/editWebsite/layoutPhotos/mobile-stacked-2-image-1-1.png';

// Public Website
import fullWidthWebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/FullWidthImages/fullwidth_desktop.jpg';
import marquee1WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MarqueeImages/marquee_desktop1.jpg';
import marquee2WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MarqueeImages/marquee_desktop2.jpg';
import marquee3WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MarqueeImages/marquee_desktop3.jpg';
import marquee4WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MarqueeImages/marquee_desktop4.jpg';
import mixed1WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MixedImages/mixed_desktop1.jpg';
import mixed2WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MixedImages/mixed_desktop2.jpg';
import slideshow1WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/SlideshowImages/slideshow_desktop1.jpg';
import slideshow2WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/SlideshowImages/slideshow_desktop2.jpg';
import stacked1WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/StackedImages/stacked_desktop1.jpg';
import stacked2WebsitePlaceholder from 'assets/images/editWebsite/websitePlaceholders/StackedImages/stacked_desktop2.jpg';

import fullWidthWebsiteMobilePlaceholder from 'assets/images/editWebsite/websitePlaceholders/FullWidthImages/fullwidth_mobile.jpg';
import marquee1WebsiteMobilePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MarqueeImages/marquee_mobile1.jpg';
import marquee2WebsiteMobilePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MarqueeImages/marquee_mobile2.jpg';
import marquee3WebsiteMobilePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MarqueeImages/marquee_mobile3.jpg';
import marquee4WebsiteMobilePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MarqueeImages/marquee_mobile4.jpg';
import mixed1WebsiteMobilePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MixedImages/mixed_mobile1.jpg';
import mixed2WebsiteMobilePlaceholder from 'assets/images/editWebsite/websitePlaceholders/MixedImages/mixed_mobile2.jpg';
import slideshowSinglepageWebsite1Placeholder from 'assets/images/editWebsite/websitePlaceholders/SlideshowImages/slideshow-singlepage-1.jpg';
import slideshowSinglepageWebsite2Placeholder from 'assets/images/editWebsite/websitePlaceholders/SlideshowImages/slideshow-singlepage-2.jpg';

// can't import BE typing (WCmsPageImageView.LayoutTypeEnum) because the TS compiler will try to convert that file into JS to use the enum values (not just types)
export type LayoutTypes =
  | 'NO_IMAGE'
  | 'SINGLE_IMAGE_SINGLE_PAGE'
  | 'SINGLE_IMAGE_MULTI_PAGE_SCALED'
  | 'SINGLE_IMAGE_MULTI_PAGE'
  | 'STACKED'
  | 'SIDE_BY_SIDE'
  | 'MARQUEE'
  | 'SLIDESHOW_SINGLE_PAGE'
  | 'SLIDESHOW_MULTI_PAGE';

export type HeroSizesType = 'RECTANGLE' | 'SQUARE' | 'FIVE_BY_SEVEN' | 'SEVEN_BY_FIVE' | 'FREEFORM';

export type LayoutPhotosLayoutSegmentsType = 'DESKTOP' | 'MOBILE';

export enum MinPhotoCount {
  SINGLE_IMAGE = 1,
  STACKED = 1,
  SIDE_BY_SIDE = 1,
  MARQUEE = 4,
  SLIDESHOW = 2,
}

export enum MaxPhotoCount {
  SINGLE_IMAGE = 1,
  STACKED = 2,
  SIDE_BY_SIDE = 2,
  MARQUEE = 6,
  SLIDESHOW = 6,
}

export const segments = [
  {
    key: 'DESKTOP' as LayoutPhotosLayoutSegmentsType,
    segmentIcon: <DeviceDesktopIcon height={24} width={24} />,
    toolTipText: 'Desktop photos',
  },
  {
    key: 'MOIBLE' as LayoutPhotosLayoutSegmentsType,
    segmentIcon: <DeviceMobileIcon height={24} width={24} />,
    toolTipText: 'Mobile photos',
  },
];

export const multiPageHeroLayouts: LayoutTypes[] = [
  'NO_IMAGE',
  'SINGLE_IMAGE_MULTI_PAGE',
  'SINGLE_IMAGE_MULTI_PAGE_SCALED',
  'SIDE_BY_SIDE',
  'STACKED',
  'SLIDESHOW_MULTI_PAGE',
  'MARQUEE',
];

export const singlePageHeroLayouts: LayoutTypes[] = [
  'SINGLE_IMAGE_SINGLE_PAGE',
  'SLIDESHOW_SINGLE_PAGE',
];

export const getHeaderText = (layoutType: LayoutTypes) => {
  switch (layoutType) {
    case 'STACKED':
      return 'Photos (2 required)';
    case 'SIDE_BY_SIDE':
      return 'Photos (2 required)';
    case 'SLIDESHOW_MULTI_PAGE':
    case 'SLIDESHOW_SINGLE_PAGE':
      return 'Photos (2 - 6 required)';
    case 'MARQUEE':
      return 'Photos (4 - 6 required)';
    default:
      return 'Photo (required)';
  }
};

export const getLayoutText = (layoutType: LayoutTypes) => {
  switch (layoutType) {
    case 'SINGLE_IMAGE_MULTI_PAGE_SCALED':
    case 'SINGLE_IMAGE_SINGLE_PAGE':
      return 'Full width';
    case 'SINGLE_IMAGE_MULTI_PAGE':
      return 'Banner';
    case 'STACKED':
      return 'Overlapping squares';
    case 'SIDE_BY_SIDE':
      return 'Mixed side-by-side';
    case 'SLIDESHOW_MULTI_PAGE':
    case 'SLIDESHOW_SINGLE_PAGE':
      return 'Slideshow';
    case 'MARQUEE':
      return 'Marquee';
    default:
      return 'Text only';
  }
};

export const getLayoutIcon = (layoutType: LayoutTypes, isHighPixelDensity?: boolean) => {
  switch (layoutType) {
    case 'SINGLE_IMAGE_MULTI_PAGE':
      return bannerAsset;
    case 'SINGLE_IMAGE_SINGLE_PAGE':
    case 'SINGLE_IMAGE_MULTI_PAGE_SCALED':
      return fullWidthAsset;
    case 'STACKED':
      return stackedAsset;
    case 'SIDE_BY_SIDE':
      return sideBySideAsset;
    case 'SLIDESHOW_MULTI_PAGE':
    case 'SLIDESHOW_SINGLE_PAGE':
      return slideshowAsset;
    case 'MARQUEE':
      return marqueeAsset;
    default:
      return isHighPixelDensity ? textOnlyHpdAsset : textOnlyAsset;
  }
};

export const getPlaceholderAsset = (
  isDesktop?: boolean,
  layoutType?: LayoutTypes,
  variant?: number
) => {
  if (!isDesktop) {
    switch (layoutType) {
      case 'STACKED':
        return variant === 1 ? mobileStacked1Placeholder : mobileStacked2Placeholder;
      case 'SIDE_BY_SIDE':
        return variant === 1 ? mobileSbs1Placeholder : mobileSbs2Placeholder;
      case 'SLIDESHOW_MULTI_PAGE':
      case 'SLIDESHOW_SINGLE_PAGE':
        return variant === 1 ? mobileSlideshow1Placeholder : mobileSlideshow2Placeholder;
      case 'MARQUEE':
        if (variant === 2) {
          return mobileMarquee4Placeholder;
        }
        if (variant === 3) {
          return mobileMarquee3Placeholder;
        }
        if (variant === 4) {
          return mobileMarquee2Placeholder;
        }
        return mobileMarquee1Placeholder;
      default:
        return mobileFullWidthPlaceholder;
    }
  }

  switch (layoutType) {
    case 'STACKED':
      return variant === 1 ? stacked1Placeholder : stacked2Placeholder;
    case 'SIDE_BY_SIDE':
      return variant === 1 ? sevenByFivePlaceholder : fiveBySevenPlaceholder;
    case 'SLIDESHOW_MULTI_PAGE':
      return variant === 1 ? slideshowMultipage1Placeholder : slideshowMultipage2Placeholder;
    case 'SLIDESHOW_SINGLE_PAGE':
      return variant === 1 ? slideshowSinglepage1Placeholder : slideshowSinglepage2Placeholder;
    case 'MARQUEE':
      if (variant === 2) {
        return marquee2Placeholder;
      }
      if (variant === 3) {
        return marquee3Placeholder;
      }
      if (variant === 4) {
        return marquee4Placeholder;
      }
      return marquee1Placeholder;
    default:
      return fullWidthPlaceholder;
  }
};

export const formatCardPhotoUrl = (url: string) => {
  return `${url}?w=300&fm=webp`;
};

export const getWebsitePlaceholderAsset = (
  layoutType?: LayoutTypes,
  isDesktop?: boolean,
  variant?: number
) => {
  if (!isDesktop) {
    switch (layoutType) {
      case 'STACKED':
        return variant === 1 ? stacked1WebsitePlaceholder : stacked2WebsitePlaceholder;
      case 'SIDE_BY_SIDE':
        return variant === 1 ? mixed1WebsiteMobilePlaceholder : mixed2WebsiteMobilePlaceholder;
      case 'SLIDESHOW_MULTI_PAGE':
      case 'SLIDESHOW_SINGLE_PAGE':
        return variant === 1
          ? slideshowSinglepageWebsite1Placeholder
          : slideshowSinglepageWebsite2Placeholder;
      case 'MARQUEE':
        if (variant === 2) {
          return marquee2WebsiteMobilePlaceholder;
        }
        if (variant === 3) {
          return marquee3WebsiteMobilePlaceholder;
        }
        if (variant === 4) {
          return marquee4WebsiteMobilePlaceholder;
        }
        return marquee1WebsiteMobilePlaceholder;
      default:
        return fullWidthWebsiteMobilePlaceholder;
    }
  }

  switch (layoutType) {
    case 'STACKED':
      return variant === 1 ? stacked1WebsitePlaceholder : stacked2WebsitePlaceholder;
    case 'SIDE_BY_SIDE':
      return variant === 1 ? mixed1WebsitePlaceholder : mixed2WebsitePlaceholder;
    case 'SLIDESHOW_MULTI_PAGE':
    case 'SLIDESHOW_SINGLE_PAGE':
      return variant === 1 ? slideshow1WebsitePlaceholder : slideshow2WebsitePlaceholder;
    case 'MARQUEE':
      if (variant === 2) {
        return marquee2WebsitePlaceholder;
      }
      if (variant === 3) {
        return marquee3WebsitePlaceholder;
      }
      if (variant === 4) {
        return marquee4WebsitePlaceholder;
      }
      return marquee1WebsitePlaceholder;
    default:
      return fullWidthWebsitePlaceholder;
  }
};

export const filterHeroPhotos = (
  heroLayout: string,
  isDesktop: boolean,
  photosList?: WCmsPageImageView[]
) => {
  if (!photosList) return [];

  // Filter photos by layout and only HERO images
  const photosByLayout = photosList.filter(
    photo =>
      photo?.layout_type === ((heroLayout as unknown) as WCmsPageImageView.LayoutTypeEnum) &&
      photo?.type === (('HERO' as unknown) as WCmsPageImageView.TypeEnum)
  );

  // Mobile is always size SQUARE
  if (!isDesktop) {
    return photosByLayout.filter(
      photo => photo.size === (('SQUARE' as unknown) as WCmsPageImageView.SizeEnum)
    );
  }

  // For Desktop filter by specific sizes
  if (isDesktop && heroLayout === 'MARQUEE') {
    return photosByLayout.filter(
      photo => photo.size === (('FREEFORM' as unknown) as WCmsPageImageView.SizeEnum)
    );
  }

  if (isDesktop && heroLayout === 'SIDE_BY_SIDE') {
    return photosByLayout.filter(
      photo =>
        photo.size === (('SEVEN_BY_FIVE' as unknown) as WCmsPageImageView.SizeEnum) ||
        photo.size === (('FIVE_BY_SEVEN' as unknown) as WCmsPageImageView.SizeEnum)
    );
  }

  if (
    isDesktop &&
    (heroLayout === 'SINGLE_IMAGE_MULTI_PAGE' ||
      heroLayout === 'SLIDESHOW_MULTI_PAGE' ||
      heroLayout === 'SINGLE_IMAGE_MULTI_PAGE_SCALED')
  ) {
    return photosByLayout.filter(
      photo => photo.size === (('RECTANGLE' as unknown) as WCmsPageImageView.SizeEnum)
    );
  }

  if (
    isDesktop &&
    (heroLayout === 'STACKED' ||
      heroLayout === 'SINGLE_IMAGE_SINGLE_PAGE' ||
      heroLayout === 'SLIDESHOW_SINGLE_PAGE')
  ) {
    return photosByLayout.filter(
      photo => photo.size === (('SQUARE' as unknown) as WCmsPageImageView.SizeEnum)
    );
  }

  // Return the filtered photo list
  return photosByLayout;
};

export const hasDismissedCookie = 'HERO_CUSTOMIZER_TOOLTIP_DISMISSED';

export const determineAddPhotoPosition = (photosList?: WCmsPageImageView[]) => {
  if (!photosList) return 1;
  const placeholderPhoto = photosList.find(photo => photo && !photo.image_url);
  return placeholderPhoto && placeholderPhoto.position
    ? placeholderPhoto.position
    : photosList.length + 1;
};
