import React from 'react';
import styled from '@emotion/styled';
import type { Dayjs } from 'dayjs';
import mapFontValuesToComponent from 'components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';
import { WeddingDateDisplayFormatEnum } from '../../../../../../util/websiteDateUtil';
import { DisplayTypeProps } from './props';
import { HorizontalContainer, HorizontalHeader, To } from '../LocationDateModule.styles';

const LocationDateModuleHorizontal = ({
  className,
  city,
  state,
  websiteDate,
  headerFontProps,
  bodyFontProps,
  textAlignment,
}: DisplayTypeProps): JSX.Element | null => {
  const StyledHorizontalHeader = mapFontValuesToComponent({
    ...headerFontProps,
    ComponentType: HorizontalHeader,
  });
  const BodyFontDivComponent = mapFontValuesToComponent({ ...bodyFontProps, ComponentType: 'div' });
  const BodyFontToComponent = mapFontValuesToComponent({ ...bodyFontProps, ComponentType: To });

  const TwoLineModule = ({
    primaryText,
    secondaryText,
    className: twoLineClassname,
  }: {
    primaryText?: string;
    secondaryText?: string;
    className?: string;
  }) => {
    if (primaryText && secondaryText) {
      return (
        <BodyFontDivComponent className={twoLineClassname}>
          <StyledHorizontalHeader>{primaryText}</StyledHorizontalHeader>
          {secondaryText}
        </BodyFontDivComponent>
      );
    }
    if (primaryText)
      return (
        <StyledHorizontalHeader className={twoLineClassname}>{primaryText}</StyledHorizontalHeader>
      );
    if (secondaryText) return <div className={twoLineClassname}>{secondaryText}</div>;
    return null;
  };

  const hasWeddingDate = Boolean(websiteDate);
  const hasWeddingLocation = Boolean(city || state);

  const LocationModule = ({
    className: locationClassname,
  }: {
    className?: string;
  }): JSX.Element => (
    <TwoLineModule className={locationClassname} primaryText={city} secondaryText={state} />
  );
  const StyledLocationModule = styled(LocationModule)`
    margin-right: 40px;

    ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
      margin-right: 0;
      margin-bottom: 40px;
    }
  `;

  const DateModule = () => {
    const renderDate = (d?: Dayjs) => (
      <TwoLineModule
        primaryText={`${d?.format('MMMM')} ${d?.format('D')}`}
        secondaryText={d?.format('YYYY')}
      />
    );
    const renderDateFormat = (): React.ReactNode | null => {
      if (websiteDate?.displayFormat === WeddingDateDisplayFormatEnum.CUSTOM) {
        return <BodyFontDivComponent>{websiteDate.customDateMessage}</BodyFontDivComponent>;
      }
      if (websiteDate?.displayFormat === WeddingDateDisplayFormatEnum.RANGE) {
        const { weddingDateRangeStart, weddingDateRangeEnd } = websiteDate || {};

        return (
          <>
            {renderDate(weddingDateRangeStart)}
            <BodyFontToComponent>to</BodyFontToComponent>
            {renderDate(weddingDateRangeEnd)}
          </>
        );
      }
      if (websiteDate?.weddingDate) {
        return renderDate(websiteDate?.weddingDate);
      }
      return null;
    };

    return <div className={className}>{renderDateFormat()}</div>;
  };

  return (
    <HorizontalContainer
      hasTwoElements={hasWeddingDate && hasWeddingLocation}
      textAlignment={textAlignment}
      className={className}
    >
      <StyledLocationModule />
      <DateModule />
    </HorizontalContainer>
  );
};

export default LocationDateModuleHorizontal;
