import styled from '@emotion/styled';
import { mapInFlowAssetToCSS } from 'components/publicWebsiteV2/util/mappers';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import type { DevicePartial } from 'components/publicWebsiteV2/util/getRelativeImgSize';
import LocationDateModule from './LocationDateModule';
import DateCountdown from './DateCountdown';
import FooterImage from './FooterImage';

export const HomepageDividerFlourish = styled.div<{
  inFlowAsset?: WInFlowThemeComponentAssetSummaryView;
  device?: DevicePartial;
  containerWidth?: number;
}>`
  margin-bottom: ${({ theme }) => theme.SPACING.XL};
  ${({ inFlowAsset, device, containerWidth, theme }) =>
    mapInFlowAssetToCSS(inFlowAsset, device, containerWidth, theme.MEDIA_QUERY)}
`;

export const PaddedContentContainer = styled.div<{
  textAlignment: string;
  addTopPadding?: boolean;
}>`
  text-align: ${({ textAlignment }) => textAlignment.toLowerCase()};
  ${({ addTopPadding, theme }) => (addTopPadding ? `padding-top: ${theme.SPACING.XL}; }` : '')}
  // Add padding to children so assets can be decorated to children components at full width
  & > * {
    padding-left: ${({ theme }) => theme.SPACING.S32};
    padding-right: ${({ theme }) => theme.SPACING.S32};
  }
`;

export const Hashtag = styled.div`
  margin-bottom: ${({ theme }) => theme.SPACING.LG};
`;

export const StyledLocationDateModule = styled(LocationDateModule)`
  margin-top: ${({ theme }) => theme.SPACING.LG};
  margin-bottom: ${({ theme }) => theme.SPACING.LG};
`;

export const StyledDateCountdown = styled(DateCountdown)`
  margin-top: ${({ theme }) => theme.SPACING.LG};
  margin-bottom: ${({ theme }) => theme.SPACING.XL};
`;

export const RSVPButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.SPACING.XL};
`;

export const StyledFooterImage = styled(FooterImage)`
  margin-top: ${({ theme }) => theme.SPACING.XL};
`;
