import styled from '@emotion/styled';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

import {
  Z_INDEX_MAP,
  Z_INDEX_HERO_IMAGE_KEY,
} from 'components/publicWebsiteV2/util/zIndexConstants';

const getContainerDesktopAspectRatio = (isNoImage?: boolean, isSideBySideLayout?: boolean) => {
  if (isNoImage) {
    return 'unset';
  }
  if (isSideBySideLayout) return '74/35'; // to maintain 7:5 and 5:7 image sizes
  return '12/7';
};

export const Container = styled.div<{
  overrideMobileHeight?: number;
  isNoImage?: boolean;
  inPreview?: string;
  isSideBySideLayout?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  overflow: hidden;
  aspect-ratio: ${({ isNoImage, isSideBySideLayout }) =>
    getContainerDesktopAspectRatio(isNoImage, isSideBySideLayout)};

  ${MEDIA_QUERY.MOBILE} {
    aspect-ratio: ${({ isNoImage }) => (isNoImage ? 'unset' : '1/1')};
  }

  ${({ inPreview, isNoImage }) =>
    inPreview === 'MOBILE' &&
    `
      aspect-ratio: ${isNoImage ? 'unset' : '1/1'};
    `};

  ${({ theme, overrideMobileHeight, inPreview }) =>
    !inPreview &&
    `
      ${theme.MEDIA_QUERY.MOBILE} {
       ${overrideMobileHeight ? `min-height: ${overrideMobileHeight}px;` : ''}
      }
    `};
`;

export const BaseHero = styled.div<{
  overrideMobileHeight?: number;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX_MAP[Z_INDEX_HERO_IMAGE_KEY]};

  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    ${({ overrideMobileHeight }) =>
      overrideMobileHeight ? `height: ${overrideMobileHeight}px;` : ''}
  }
`;
