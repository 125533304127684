import React, { useState, useEffect, useRef, createRef } from 'react';
import type { WAbsoluteThemeComponentAssetView } from '@zola/svc-web-api-ts-client';
import { useForceUpdateOnWindowResize } from '@zola/zola-ui/src/hooks/useForceUpdateOnWindowResize';

/**
 * Hook to crop absolute assets that extend beyond the container bottom,
 * in order to accommodate no overflow on the bottom while still allowing overflow at the top
 * Returns:
 * - refs to attach to container and image elements
 * - an array of CroppedAbsoluteAssetDisplayType that determines
 *      - whether to display
 *      - whether to crop at what height
 */

export type CroppedAbsoluteAssetDisplayType = {
  display: boolean;
  crop?: {
    // new height of cropped image
    height: number;
  };
};

const useCroppedBottomAbsoluteAssets = (
  absoluteAssets?: WAbsoluteThemeComponentAssetView[],
  inPreview?: boolean
) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const elementsRef = useRef<React.RefObject<HTMLImageElement>[]>(
    (absoluteAssets || []).map(() => createRef())
  );
  const [originalDimensions, setOriginalDimensions] = useState<number[]>([]);

  useForceUpdateOnWindowResize();

  useEffect(() => {
    if (typeof window !== 'undefined' && !inPreview) {
      // Save the original aspect ratio of the image on first render
      // Needed to determine expected height
      const aspectRatios = elementsRef.current.map(ref => {
        if (ref.current) {
          const assetRect = ref.current.getBoundingClientRect();
          const aspectRatio = assetRect.height / assetRect.width;
          return aspectRatio;
        }
        return 0;
      });
      setOriginalDimensions(aspectRatios);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let croppedDimensions: CroppedAbsoluteAssetDisplayType[] = [];
  if (typeof window !== 'undefined') {
    croppedDimensions = elementsRef.current.map((ref, i) => {
      if (ref.current && containerRef.current) {
        const containerRect = containerRef?.current?.getBoundingClientRect();
        const assetRect = ref.current.getBoundingClientRect();
        // Only display if asset overlaps with container
        const display = assetRect.top <= containerRect.bottom;
        // If overlaps and extends below container, asset needs to be cropped
        // Because already cropped images will have adjusted heights,
        // calculate expected height and bottom values based on saved aspect ratios
        const expectedHeight = originalDimensions[i] * assetRect.width;
        const expectedBottom = assetRect.y + expectedHeight;
        const needsCropping = display && expectedBottom > containerRect.bottom;
        if (!needsCropping) {
          return { display };
        }
        // Only return crop object if requires cropping
        const crop = {
          height: containerRect.bottom - assetRect.top,
        };
        return {
          display,
          crop,
        };
      }
      return {
        display: true,
      };
    });
  }

  return { containerRef, elementsRef, croppedDimensions };
};

export default useCroppedBottomAbsoluteAssets;
