import styled from '@emotion/styled';

export const TimeInterval = styled.span<{ addSpacing: boolean }>`
  ${({ addSpacing, theme }) => addSpacing && `margin-left: ${theme.SPACING.S16};`}
`;

export const DesktopLabel = styled.span`
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;

export const MobileLabel = styled.span`
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_MOBILE} {
    display: none;
  }
`;
