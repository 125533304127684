import React, { useContext } from 'react';
import dynamic from 'next/dynamic';

// types
import type { WCmsHomepageView, WCmsPageImageView } from '@zola/svc-web-api-ts-client';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { DeviceContext } from 'contexts/DeviceContext';

// hooks
import useRefScrollProgress from 'lib/hooks/useRefScrollProgress';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';

// utils
import { renderAbsoluteAssets } from 'components/publicWebsiteV2/util/renderHelpers';
import { filterHeroPhotos } from 'components/manage/EditWebsite/common/HeroCustomizer/utils';

// styles
import { Container } from './MultiPageHeroContainer.styles';
import { FoilAnimationProvider } from '../FoilAnimation/FoilAnimationContext/FoilAnimationProvider';

// dynamic import components
const MultiPageHeroSingleImage = dynamic(() =>
  import(
    'components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroSingleImage'
  )
);
const MultiPageHeroOnlyText = dynamic(() =>
  import(
    'components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroOnlyText'
  )
);
const MultiPageHeroMarquee = dynamic(() =>
  import('components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroMarquee')
);
const MultiPageHeroSideBySide = dynamic(() =>
  import(
    'components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroSideBySide'
  )
);
const MultiPageHeroSlideshow = dynamic(() =>
  import(
    'components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroSlideshow'
  )
);
const MultiPageHeroStacked = dynamic(() =>
  import('components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroStacked')
);
const MultiPageHeroSingleImageNarrow = dynamic(() =>
  import(
    'components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroSingleImageNarrow'
  )
);

export type MultiPageHeroContainerProps = {
  pageData?: WCmsHomepageView;
  isSamplePage?: boolean;
  onDownCaretClick?: () => void;
};

const MultiPageHeroContainer: React.FC<MultiPageHeroContainerProps> = ({
  pageData,
  isSamplePage,
  onDownCaretClick,
}) => {
  const {
    state: { wedding, inPreview },
  } = useWebsiteThemeContext();

  const { device } = useContext(DeviceContext);

  const { title, hero_image_layout_type } = pageData || {};

  const { absolute_assets: heroAbsoluteAssets } =
    wedding?.public_theme_v2?.components?.HERO_HOME || {};

  const { ref: heroWrapperRef } = useRefScrollProgress();
  const containerDimensions = useContainerDimensions(heroWrapperRef);
  const { width: containerWidth } = containerDimensions;

  const overrideMobileHeight = inPreview && inPreview === 'MOBILE' ? containerWidth : undefined;
  const heroImageLayoutType = hero_image_layout_type
    ? `${hero_image_layout_type}`
    : 'SINGLE_IMAGE_MULTI_PAGE';
  const isNoImage = heroImageLayoutType === 'NO_IMAGE';
  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && !!device?.isDesktop());
  const imagesFilteredByLayout = filterHeroPhotos(heroImageLayoutType, isDesktop, pageData?.images);

  const renderHeroVariant = () => {
    if (isNoImage) return null;

    switch (heroImageLayoutType) {
      case 'STACKED':
        return (
          <MultiPageHeroStacked
            images={imagesFilteredByLayout}
            inPreview={inPreview}
            overrideMobileHeight={overrideMobileHeight}
          />
        );
      case 'SIDE_BY_SIDE':
        return (
          <MultiPageHeroSideBySide
            images={imagesFilteredByLayout}
            inPreview={inPreview}
            overrideMobileHeight={overrideMobileHeight}
          />
        );
      case 'SLIDESHOW_MULTI_PAGE':
        return (
          <MultiPageHeroSlideshow
            images={imagesFilteredByLayout}
            inPreview={inPreview}
            overrideMobileHeight={overrideMobileHeight}
          />
        );
      case 'MARQUEE':
        return (
          <MultiPageHeroMarquee
            images={imagesFilteredByLayout}
            inPreview={inPreview}
            overrideMobileHeight={overrideMobileHeight}
          />
        );
      default:
        return (
          <MultiPageHeroSingleImage
            images={imagesFilteredByLayout}
            inPreview={inPreview}
            overrideMobileHeight={overrideMobileHeight}
          />
        );
    }
  };

  if (heroImageLayoutType === 'SINGLE_IMAGE_MULTI_PAGE') {
    const images = (pageData?.images || []).filter(
      photo =>
        photo?.layout_type ===
          (('SINGLE_IMAGE_MULTI_PAGE' as unknown) as WCmsPageImageView.LayoutTypeEnum) &&
        photo?.type === (('HERO' as unknown) as WCmsPageImageView.TypeEnum)
    );
    const isDesktopOrTablet = inPreview === 'DESKTOP' || (!inPreview && !device?.isMobile());

    return (
      <FoilAnimationProvider>
        <MultiPageHeroSingleImageNarrow
          images={images}
          isDesktop={isDesktopOrTablet}
          isSamplePage={isSamplePage}
          onDownCaretClick={onDownCaretClick}
          title={title}
        />
      </FoilAnimationProvider>
    );
  }

  return (
    <FoilAnimationProvider>
      <Container
        data-testid="MultiPageHeroContainer"
        overrideMobileHeight={overrideMobileHeight}
        isNoImage={isNoImage}
        ref={heroWrapperRef}
        inPreview={inPreview}
        isSideBySideLayout={heroImageLayoutType === 'SIDE_BY_SIDE'}
      >
        {renderHeroVariant()}
        {/* Text component is rendered across all hero varients (if there is a title) */}
        <MultiPageHeroOnlyText title={title} hasBackgroundImage={!isNoImage} />
        {/* No scroll down caret in the new varients */}
        {/* Render absolute assets if the theme has them */}
        {renderAbsoluteAssets({ a: heroAbsoluteAssets, containerWidth, device })}
      </Container>
    </FoilAnimationProvider>
  );
};

export default MultiPageHeroContainer;
