import { useRef, useState, useEffect } from 'react';

// https://gist.github.com/coleturner/34396fb826c12fbd88d6591173d178c2?permalink_comment_id=4211920#gistcomment-4211920

export const useRefScrollProgress = (watch?: unknown) => {
  const ref = useRef<HTMLDivElement>(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = rect.top + scrollTop - window.innerHeight;

    const documentHeight = document.body.clientHeight - window.innerHeight;

    setStart(offsetTop / documentHeight);
    setEnd((offsetTop + window.innerHeight + rect.height) / documentHeight);
  }, [watch]);

  return { ref, start, end };
};

export default useRefScrollProgress;
