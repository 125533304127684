import dayjs from 'dayjs';
/**
 *
 * @param d1 date to count down to (in future)
 * @param d2 date to start count down; default is now
 * @returns time left in cumulative, days, hours, minutes, seconds
 */
export const calculateTimeLeft = (
  d1: string,
  d2?: string
): Partial<Record<keyof TimeUnitStringMapType, number>> => {
  const day2 = d2 ? new Date(d2) : new Date();
  const difference = dayjs(d1).diff(dayjs(day2));

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      day: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hour: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minute: Math.floor((difference / 1000 / 60) % 60),
      second: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const stripTimeStamp = (dateWithTimestamp: Date | string) =>
  ((dateWithTimestamp as unknown) as string).slice(0, 10);

type TimeUnitStringCollection = {
  DESKTOP: string;
  MOBILE: string;
};

export type TimeUnitStringMapType = {
  day: TimeUnitStringCollection;
  hour: TimeUnitStringCollection;
  minute: TimeUnitStringCollection;
  second: TimeUnitStringCollection;
};

const UNIT_STR_MAP: TimeUnitStringMapType = {
  day: {
    DESKTOP: 'day',
    MOBILE: 'd',
  },
  hour: {
    DESKTOP: 'hour',
    MOBILE: 'h',
  },
  minute: {
    DESKTOP: 'minute',
    MOBILE: 'min',
  },
  second: {
    DESKTOP: 'second',
    MOBILE: 's',
  },
};

/**
 *
 * @param value time value
 * @param unit time unit
 * @returns readable string representing time with space at end
 */
export const mapTimeToStringCollection = (
  value: number | undefined,
  unitKey: keyof TimeUnitStringMapType
): TimeUnitStringCollection => ({
  DESKTOP:
    value && value > 0 ? `${value} ${UNIT_STR_MAP[unitKey].DESKTOP}${value === 1 ? '' : 's'}` : '',
  MOBILE: value && value > 0 ? `${value} ${UNIT_STR_MAP[unitKey].MOBILE}` : '',
});

export const getCountdownStrings = (
  t: Partial<Record<keyof TimeUnitStringMapType, number>>
): TimeUnitStringCollection[] => {
  const timeKeys = Object.keys(t);

  const timeString = ((timeKeys as unknown) as (keyof TimeUnitStringMapType)[])
    .map(k => {
      return mapTimeToStringCollection(t[k], k);
    })
    .filter(s => s.DESKTOP !== ''); // filter out empty units with empty strings (value = 0)
  return timeString;
};
